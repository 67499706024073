import { createAsyncThunk } from '@reduxjs/toolkit'
import semver from 'semver/preload'

import { BLEND_MODES, LAT_SEMVER_0_1_0 } from '../../constants/constants'
import currentPlaySlice from '../../reducers/currentPlaySlice'
import sessionSlice from '../../reducers/sessionSlice'
import { selectCurrentPlaylistInfo } from '../../selectors/current-play-selectors'
import { selectLocalTimings, selectLocalTracks } from '../../selectors/local-authoring-selectors'
import {
  selectCurrentMatchSlug,
  selectCurrentUsername,
  selectIsCurrentOwnedMatch,
} from '../../selectors/session-selectors'
import trackMixer from '../../services/TrackMixer'
import { updateLocalAudioDuration, updateLocalTrack } from '../authoring/update-local-track'
import { AppDispatch, RootState } from '../../reducers'
import { AudioData, ExtendedTrackInfo } from '../../types'
import { splitLinesAndRemoveMeta } from '../../util/lyrics/split-lyrics'
import trackLoaded from './trackLoaded'

const processTrack = createAsyncThunk<
  Promise<boolean>,
  {
    trackSlug: string
    trackInfo: ExtendedTrackInfo
    lyrics: string
    timing: string
    audioDuration: number
  },
  { state: RootState; dispatch: AppDispatch }
>(
  'load/processTrack',
  ({ trackSlug, trackInfo, lyrics, timing, audioDuration }, { dispatch, getState }) => {
    const {
      paths,
      title,
      artist,
      links,
      owner,
      wordCount,
      timedWordCount,
      timestamps,
      version,
      duration,
      imageAttributes,
    } = trackInfo
    const {
      audioUrl: remotePath,
      textUrl: remoteLyricsPath,
      lrcUrl: remoteTimingPath,
      imageUrl: remoteImagePath,
    } = paths
    return new Promise<boolean>((resolve) => {
      const state = getState()
      const currUsername = selectCurrentUsername(state)
      const matchSlug = selectCurrentMatchSlug(state)
      const localTracks = selectLocalTracks(state)
      const timings = selectLocalTimings(state)
      const localTiming = timings[trackSlug]
      const { slug, prefix } = selectCurrentPlaylistInfo(state)
      const playlistSlug = `${prefix}:${slug}`
      const isCurrentPlaylistLocal = selectIsCurrentOwnedMatch(state)
      const strangerCanTakeOver = semver.satisfies(version, `>=${LAT_SEMVER_0_1_0}`)
      const canTakeOver = owner === currUsername || strangerCanTakeOver
      const isLocal = isCurrentPlaylistLocal && canTakeOver
      const lyricsWithoutMeta = splitLinesAndRemoveMeta(lyrics).join('\n')
      const timingLinesWithoutMeta = timing ? splitLinesAndRemoveMeta(timing) : []
      const timingWithoutMeta = timingLinesWithoutMeta.join('\n')

      if (isLocal) {
        const localTrack = localTracks[trackSlug]
        if (!localTrack) {
          if (duration !== audioDuration) {
            console.log(`updating duration for new ${trackSlug}: ${duration} -> ${audioDuration}`)
          }
          const audioData: AudioData = {
            title,
            artist,
            duration: audioDuration,
            links,
            owner,
            remoteImagePath,
            remotePath,
            remoteLyricsPath,
            lyrics: lyricsWithoutMeta,
            remoteTimingPath,
            wordCount,
            timedWordCount,
            ...timestamps,
          }
          dispatch(updateLocalTrack({ trackSlug, audioData }))
        } else {
          if (duration !== audioDuration) {
            console.log(`updating duration for ${trackSlug}: ${duration} -> ${audioDuration}`)
            dispatch(updateLocalAudioDuration(trackSlug, audioDuration))
          }
        }
      }
      const extendedTrackInfo = {
        ...trackInfo,
        matchSlug,
        playlistSlug,
        duration: audioDuration,
      }
      dispatch(currentPlaySlice.actions.setTrackInfo(extendedTrackInfo))
      dispatch(
        trackLoaded({ trackInfo, isLocal, txt: lyricsWithoutMeta, timing: timingWithoutMeta })
      ).then(() => {
        const { blendMode, theme } = imageAttributes
        if (theme) {
          dispatch(sessionSlice.actions.setCurrentThemeIndexByName(theme))
        }
        const blendModeIndex = BLEND_MODES.findIndex((mode) => {
          return mode === blendMode
        })
        if (blendModeIndex >= 0) {
          dispatch(sessionSlice.actions.setCurrentBackgroundBlendMode(blendModeIndex))
        }
        trackMixer.initUI()
        if (timing) {
          trackMixer.lyricTimingLoaded(timingLinesWithoutMeta, localTiming)
          dispatch(currentPlaySlice.actions.setIsPlayable(true)) // TODO: criteria?
          dispatch(currentPlaySlice.actions.setLRClines(timingLinesWithoutMeta))
        }
        resolve(true)
      })
    })
  }
)

export default processTrack
