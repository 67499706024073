import { createAsyncThunk } from '@reduxjs/toolkit'

import { GREEDY_LINE_BREAK_REGEX } from '../constants/utility-constants'
import currentPlaySlice from '../reducers/currentPlaySlice'
import {
  selectCurrentTrackMeta,
  selectCurrentTrackSlug,
  selectIsScoreDirty,
} from '../selectors/current-play-selectors'
import { selectMode } from '../selectors/session-selectors'
import { updateLocalTiming } from './authoring/update-local-track'
import { saveLocalTiming } from './authoring/local-storage-actions'
import { AppDispatch, RootState } from '../reducers'
import Gamer from '../services/Gamer'

const Download = require('downloadjs')

const doSave = createAsyncThunk<
  void,
  { isDownload?: boolean; gamer: Gamer },
  { state: RootState; dispatch: AppDispatch }
>('save', ({ isDownload, gamer }, { dispatch, getState }) => {
  const state = getState()
  const trackSlug = selectCurrentTrackSlug(state)
  const mode = selectMode(state)
  const currentLRC = gamer.currentLRC
  const exportLRC = () => {
    const meta = selectCurrentTrackMeta(state)
    Download(meta.concat(currentLRC), `${trackSlug}.lrc`, 'text/plain')
    const currentLyrics = gamer.currentLyricsText
    Download(meta.concat(currentLyrics), `${trackSlug}.txt`, 'text/plain')
  }
  switch (mode) {
    case 'edit':
      if (isDownload) {
        exportLRC()
      } else {
        const isScoreDirty = selectIsScoreDirty(state)
        if (!isScoreDirty) {
          return
        }
        const { timedWordCount } = gamer.vizBuilder
        dispatch(updateLocalTiming(trackSlug, currentLRC, timedWordCount))
        const timingLines = currentLRC.split(GREEDY_LINE_BREAK_REGEX)
        dispatch(currentPlaySlice.actions.setLRClines(timingLines))
        dispatch(currentPlaySlice.actions.setIsTimingDirty(true))
        dispatch(currentPlaySlice.actions.setIsScoreDirty(false))
      }
      break
    case 'play':
      if (isDownload) {
        exportLRC()
      } else {
        const scoreSeconds = gamer.scoreSeconds
        dispatch(saveLocalTiming({ trackSlug, timing: currentLRC, scoreSeconds }))
        dispatch(currentPlaySlice.actions.setIsScoreDirty(false))
      }
      break
    case 'viz':
      // TODO:
      break
    default:
  }
})

export const removeLocalSong = (songInfo: any) => {
  console.log('not yet implemented') // TODO:
}

// desktop only
export const saveLocal = () => {
  // if (!this.isCurrPlaylistLocal) {
  //   return; // HACK until we support saving non-local
  // }
  // const songSlug = this.currentSongSlug; // TODO: might be empty if no song picked. FIX: disallow, or get from lyrics?
  // const lrcText = this.currentLRC;
  // this.playlistModel.updateReference(songSlug,lrcText);
}

export default doSave
