import { createAsyncThunk } from '@reduxjs/toolkit'

import { GREEDY_LINE_BREAK_REGEX } from '../../constants/utility-constants'
import { AppDispatch, RootState } from '../../reducers'
import currentPlaySlice from '../../reducers/currentPlaySlice'
import modalsSlice from '../../reducers/modalsSlice'
import {
  selectCurrentLyrics,
  selectCurrentTrackSlug,
  selectDirtyState,
} from '../../selectors/current-play-selectors'
import { updateLocalLyrics, updateLocalTiming } from './update-local-track'
import Gamer from '../../services/Gamer'

const doSplit = createAsyncThunk<
  void,
  { isNeedUserInput: boolean; gamer: Gamer },
  { state: RootState; dispatch: AppDispatch }
>('load/doSplit', ({ isNeedUserInput, gamer }, { dispatch, getState }) => {
  gamer.stop()
  dispatch(currentPlaySlice.actions.setIsPlayable(false))
  dispatch(currentPlaySlice.actions.setLRClines(null))
  gamer.initUI(!isNeedUserInput)
  gamer.recalc()

  if (isNeedUserInput) {
    dispatch(modalsSlice.actions.toggleTrackInfo(true))
  } else {
    const state = getState()
    const trackSlug = selectCurrentTrackSlug(state) // TODO: might be empty if no song picked. FIX: disallow, or get from lyrics?
    const lyrics = selectCurrentLyrics(state)
    const { isLyricsDirty } = selectDirtyState(state)
    const { wordCount, timedWordCount: prevTimedWordCount } = gamer.vizBuilder
    dispatch(updateLocalLyrics(trackSlug, lyrics, wordCount, isLyricsDirty))
    const { currentLRC } = gamer
    const { timedWordCount: currTimedWordCount } = gamer.vizBuilder
    if (prevTimedWordCount || currTimedWordCount) {
      gamer.timedWordCount = currTimedWordCount
      const timingLines = currentLRC.split(GREEDY_LINE_BREAK_REGEX)
      dispatch(currentPlaySlice.actions.setIsPlayable(true))
      dispatch(currentPlaySlice.actions.setLRClines(timingLines))
      dispatch(updateLocalTiming(trackSlug, currentLRC, currTimedWordCount))
    }
  }
})

export default doSplit
